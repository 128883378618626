<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="editEmployee()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_EMPLOYEE')}}
                    </button-field>
                    <button-field @click="toggleModal('DashboardEmployeeCardsImport')"
                                  icon="file-import"
                                  color="secondary">{{$ml.get('IMPORT_FROM_CSV')}}
                    </button-field>
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>
            </div>

            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >
                        <template v-slot:head(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                @change="toggleSelectAll"
                                :indeterminate="someRowsSelected && !allRowsSelected"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedRows[data.item.id]"
                                @change="onRowSelect(data.item.id, $event)"
                                :value="true"
                                :unchecked-value="false"
                                variant="secondary"
                                class="accent-secondary b-table-checkbox"
                            />
                        </template>

                        <template #cell(attributes.profilePicture)="data">
                            <div class="position-relative">
                                <span class="status-circle"
                                      :class="sentStatusClass(data.item)"
                                      v-tooltip="sentOnTooltip(data.item)"
                                >
                                </span>
                                <span class="profile-avatar text-center cursor-pointer" @click="previewCards(data.item.id, data.item.attributes.cardToken)">
                                    <img v-if="data.item.attributes.profilePicture"
                                            :src="data.item.attributes.profilePicture._url"
                                            :alt="data.item.attributes.firstName + ' ' + data.item.attributes.lastName"
                                     >
                                    <font-awesome-icon v-else color="gray" icon="user" />
                                </span>
                            </div>
                        </template>

                        <template #cell(attributes.officeAddress)="data">
                            <div class="ellipsis" v-if="data.item.attributes.officeAddress">{{data.item.attributes.officeAddress.attributes.officeLabel}}</div>
                        </template>

                        <template #cell(attributes.position)="data">
                            {{data.item.attributes.position}}
                        </template>

                        <template #cell(attributes.mobilePhone)="data">
                            {{data.item.attributes.mobilePhone}}
                        </template>

                        <template #cell(attributes.email)="data">
                            {{data.item.attributes.email}}
                        </template>

                        <template #cell(attributes.createdAt)="data">
                            <div class="ellipsis">{{niceDate(data.item.attributes.createdAt)}}</div>
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field :disabled="sharing"
                                              circle
                                              outline
                                              @click="previewCards([data.item.id], [data.item.attributes.cardToken])"
                                              icon="eye"
                                              class="table-action-btn"
                                              v-tooltip="'Preview card'"
                                              color="dark"></button-field>
                                <button-field :disabled="sharing"
                                              circle
                                              outline
                                              @click="copyCardLink([data.item.id], [data.item.attributes.cardToken])"
                                              icon="copy"
                                              class="table-action-btn"
                                              v-tooltip="'Copy card link'"
                                              color="dark"></button-field>
                                <button-field :disabled="sharing || !designReady"
                                              circle
                                              outline
                                              @click="shareCards([data.item.id])"
                                              icon="paper-plane"
                                              class="table-action-btn"
                                              v-tooltip="designReady ? 'Send card' : 'Sending cards will be enabled once design is ready'"
                                              color="secondary"></button-field>
                                <button-field @click="editEmployee(data.item.id)"
                                              circle
                                              outline
                                              icon="edit"
                                              class="table-action-btn"
                                              v-tooltip="'Edit information'"
                                              color="primary"></button-field>
                                <button-field @click="deleteEmployee(data.item.id)"
                                              circle
                                              outline
                                              icon="trash-alt"
                                              class="table-action-btn"
                                              v-tooltip="'Delete card'"
                                              color="error"></button-field>
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field icon="paper-plane"
                                  color="secondary"
                                  :disabled="!selectedRowsTrue.length || sharing || !designReady"
                                  :loading="sharing"
                                  v-tooltip="designReady ? '' : 'Sending cards will be enabled once design is ready'"
                                  @click="shareCards(selectedRowsTrue)">{{$ml.get('SEND_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>

        <b-modal id="design-not-ready"
            :ok-only="true"
                 :ok-variant="'secondary'"
        >
            <div class="text-center">
                <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-primary mb-2"
                    size="2x" />
                <h5 class="pb-3">Business card Design not ready yet!</h5>
                <p class="text-dark font-small-3">Your digital business card design is currently being prepared!</p><p class="font-small-3">Card preview, sharing and installation is therefore not yet available! You will receive a notification e-mail once everything is ready.</p>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "DashboardEmployeeCards",
        computed: {
            userCompany() {
                return this.$store.state.userCompany;
            },
            designReady() {
                if(this.userCompany && this.userCompany.attributes.status !== '01_new'){
                    return true;
                }else{
                    return false;
                }
            },
            currentPageItems() {
                return this.tableRows.slice(
                    (this.curPageValue - 1) * this.perPageValue,
                    this.curPageValue * this.perPageValue
                );
            },
            allRowsSelected() {
                return this.currentPageItems.length > 0 && this.currentPageItems.every(item => this.selectedRows[item.id]);
            },
            someRowsSelected() {
                const selectedCount = this.currentPageItems.filter(item => this.selectedRows[item.id]).length;
                return selectedCount > 0 && selectedCount < this.currentPageItems.length;
            },
            tableRows() {
                return this.$store.state.employeeCards;
            },
            resultsPerPage() {
                return this.$store.state.employeeCardsPP;
            },
            totalResults() {
                return this.$store.state.employeeCardCount;
            },
            resultsFrom() {
                if(this.$store.state.employeeCardCount == 0) {
                    return 0;
                }
                else {
                    return ((this.curPageValue-1)*this.perPageValue)+1;
                }

            },
            resultsTo() {
                let pageMaxResults = this.curPageValue*this.perPageValue;

                if(pageMaxResults > this.$store.state.employeeCardCount){
                    return this.$store.state.employeeCardCount;
                }else{
                    return pageMaxResults;
                }

            },
            getCurPageValue() {
                return this.$store.state.employeeCardPage;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                        {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            maxPages() {
              return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                selectAllStatus: false,
                selectedRows: {},
                toggleAllRows: false,
                perPageOptions: [
                    {
                        value: 5,
                        text: 5
                    },
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                tableColumns: [
                    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
                    { key: 'attributes.profilePicture', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                    { key: 'attributes.firstName', label: 'First name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.lastName', label: 'Last name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.position', label: 'Position', thClass: 'font-bebas-neue font-small-4', tdClass:'td-elipsis', sortable: true},
                    { key: 'attributes.mobilePhone', label: 'Mobile phone', thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.email', label: 'Email', thClass: 'font-bebas-neue font-small-4', tdClass:'td-elipsis', sortable: true},
                    { key: 'attributes.officeAddress', label: 'Office', thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.createdAt', label: 'Created at', thClass: 'font-bebas-neue font-small-4', sortable: true},
                    {
                        key: 'actions',
                        label: 'Actions',
                        thClass: 'font-bebas-neue text-center font-small-4',
                        tdClass: 'text-right',
                        sortable: false
                    }
                ],
                emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',

                curPageValue: 1,
                perPageValue: 25,
                sharing: false,
                searchString: null,
            }
        },
        mounted() {
            let self = this;
            this.$store.dispatch('getCompany').then(() => {
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            copyCardLink(ids, token){
                let self = this;
                if(self.designReady){
                    let url = process.env.VUE_APP_BASE_API_URL+"cards/"+ids+"/"+token;
                    navigator.clipboard.writeText(url).then(function() {
                        self.$bvToast.toast('Card link copied to clipboard!', {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        });
                    }, function() {
                        self.$bvToast.toast('Failed to copy card link to clipboard!', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        });
                    });
                } else {
                    self.$bvModal.show('design-not-ready');
                }
            },
            toggleSelectAll() {
                const newState = !this.allRowsSelected;
                this.currentPageItems.forEach(item => {
                    this.$set(this.selectedRows, item.id, newState);
                });
                this.updateSelectAllStatus();
            },
            onRowSelect(id, event) {
                // Safely check if event and event.target exist before accessing properties
                const isChecked = event && event.target && event.target.checked !== undefined
                    ? event.target.checked
                    : this.selectedRows[id]; // Default to the current state if event is not well-formed

                this.$set(this.selectedRows, id, isChecked);
                this.updateSelectAllStatus();
            },
            updateSelectAllStatus() {
                if (this.allRowsSelected) {
                    this.selectAllStatus = true;
                } else if (this.someRowsSelected) {
                    this.selectAllStatus = null; // Set to null to reflect the indeterminate state
                } else {
                    this.selectAllStatus = false;
                }
            },
            onSelectAll(val) {
                const currentPageItems = this.tableRows.slice(
                    (this.curPageValue - 1) * this.perPageValue,
                    this.curPageValue * this.perPageValue
                );

                currentPageItems.forEach(item => {
                    this.$set(this.selectedRows, item.id, val);
                });

                this.selectAllStatus = val;
            },
            sentStatusClass(item){
                if(item.attributes && item.attributes.sentAt){
                    return 'active';
                }else{
                    return 'not-sent';
                }
            },
            sentOnTooltip(item){
                if(item.attributes && item.attributes.sentAt){
                    return this.$ml.get('Sent_on') +' '+ this.niceDate(item.attributes.sentAt);
                }else{
                    return this.$ml.get('Card_not_yet_sent');
                }
            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }

            },
            updateResultsPP(){
                let self = this;
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            },

            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            editEmployee(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsForm', data: {id: id}})
            },
            deleteEmployee(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsDelete', data: {ids: [id]}})
            },

            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardEmployeeCardsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            shareCards(ids) {
                this.$store.commit('toggleDashboardModal', {name: 'EmployeeCards_Share', data: {ids: ids}})
            },
            previewCards(ids,token) {
                if(this.designReady){
                    window.open(process.env.VUE_APP_BASE_API_URL+"cards/"+ids+"/"+token, '_blank');
                } else {
                    this.$bvModal.show('design-not-ready');
                }
            },
            doSearch(searchString){
                this.$store.dispatch('getEmployeeCards', {searchTerm : searchString})
            }
        },
        watch : {
            selectedRows: {
                handler() {
                    this.updateSelectAllStatus();
                },
                deep: true
            },
            selectAllStatus() {
                this.$nextTick(() => {
                    if (this.allRowsSelected) {
                        this.selectAllStatus = true;
                    } else if (this.someRowsSelected) {
                        this.selectAllStatus = null; // Represents indeterminate state
                    } else {
                        this.selectAllStatus = false;
                    }
                });
            },
            toggleAllRows(newVal){
                if(newVal){
                    this.selectedRows = {};
                    for (const key in this.tableRows) {
                        this.selectedRows[this.tableRows[key].id] = true;
                    }
                }else {
                    this.selectedRows = {};
                }
            },
            searchString(newSearch){
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
            perPageValue(newVal){
                this.$store.commit('setEmployeeCardsPP', newVal);
                this.$store.dispatch('setEmployeeCardPage', 1);
                this.curPageValue = 1;
                this.updateResultsPP();
            },
            curPageValue(newPageVal){
                this.$store.commit('setEmployeeCardPage', newPageVal);
                this.updateResultsPP();
                this.updateSelectAllStatus();
            },
        }
    }
</script>

<style scoped>

</style>
