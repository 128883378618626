export const en = {
    forms: {
        protectedDocs: {
            documentName: 'Document name',
            documentRevision: 'Document revision',
            documentType: 'Document type',
            documentLanguage: 'Document language',
            documentDescription: 'Document description',
            save: 'Save',
            dropzonePlaceholder: 'DOCUMENT FILE<br>(.PDF ONLY)',

            title: 'Protected Documents',
            description: 'This section is dedicated to the protected documents that you can share with your clients. You can upload any type of document here, and it will be available for download by your clients.',
            upload: 'Upload a new document',
            delete: 'Delete document',
            download: 'Download document',
            noDocs: 'No documents uploaded yet',
            deleteConfirm: 'Are you sure you want to delete this document?',
            deleteSuccess: 'Document deleted successfully!',
            uploadSuccess: 'Document uploaded successfully!',
            uploadError: 'An error occurred while uploading the document. Please try again.',
            downloadError: 'An error occurred while downloading the document. Please try again.',
        },
    },
    buttons: {
        ADD_DOCUMENT: 'ADD DOCUMENT',
        DELETE: 'DELETE',
        SAVE: 'SAVE',
        REVIEW_AND_SUBSCRIBE: 'Review billing data and subscribe',
        SUBSCRIBE: 'SUBSCRIBE',
        NEXT: 'NEXT',
        SIGN_IN: 'SIGN IN',

    },
    tooltips: {
        EDIT_DOCUMENT: 'EDIT DOCUMENT',
        DELETE: 'DELETE'
    },
    notification: {
        subscription_confirmed: 'Thank you! Your subscription has been confirmed!'
    },
    tables:  {
        headers: {
            revision: 'Revision',
        },
        actions: {
            edit: 'Edit',
            delete: 'Delete',
            download: 'Download',
            edit_trial: 'Edit trial',
            create_user: 'Create user',
        }
    },
    nav: {
        profileDropdown: {
            BILLING_AND_SUBSCRIPTION: 'Billing and subscription',
            PROFILE_SETTINGS: 'Profile settings',
        },
        main: {
            KNOWLEDGE_CENTER: 'Knowledge center',
        }
    },
    subscribed_on: 'Subscribed on',
    since: 'since',
    EMPLOYEE_CARDS: 'EMPLOYEE CARDS',
    CARD_REPORTS: 'CARD REPORTS',
    BILLING_ESTIMATE: 'BILLING ESTIMATES',
    BILLING_PERIOD: 'BILLING PERIOD',
    NUMBER_OF_CARDS: 'NUMBER OF CARDS',
    PRICING_LEVEL: 'PRICING LEVEL',
    PRICE_PER_CARD: 'PRICE PER CARD',
    TOTAL: 'TOTAL',
    N_A: 'N/A',
    ALLTIME_OR_MONTHLY: 'Select the report that you would like to view',
    EXPORT_TO_CSV: 'EXPORT TO CSV',
    CARD_DESIGN: 'CARD DESIGN',
    COMPANY_COORDINATES: 'OFFICE ADDRESSES',
    CLIENTS: 'CLIENTS',
    PROMOS: 'PROMOS',
    USERS: 'USERS',
    HAS_USERS: 'HAS USERS',
    PHOTO: 'PHOTO',
    MANAGE_CLIENTS: 'MANAGE ALL CLIENTS',
    ADMIN_AREA: 'ADMIN AREA',
    UPGRADE_TO_AGENCY: 'UPGRADE TO AGENCY',
    REQUEST_UPGRADE: 'REQUEST UPGRADE',
    YOUR_AGENCY_PROFILE: 'YOUR AGENCY PROFILE',
    ADD_CLIENT: 'ADD CLIENT',
    ADD_USER: 'ADD USER',
    ADD_NEW_USER: 'ADD NEW USER',
    CREATE_MY_BUSINESS_CARD: 'CREATE MY BUSINESS CARD',
    RETURN_TO_FORM: 'Return to form',
    DELETE_EXISTING_USER: 'DELETE EXISTING USER',
    DELETE_INVOICE: 'DELETE INVOICE',
    CURRENCY: 'CURRENCY',
    FISCAL_YEAR: 'FISCAL YEAR',
    INVOICE_AMOUNT: 'INVOICE AMOUNT',
    INVOICE_AMOUNT_WITH_VAT: 'INVOICE AMOUNT (WITH VAT)',
    INVOICE_NET_AMOUNT: 'INVOICE NET AMOUNT',
    INVOICE_NUMBER: 'INVOICE NUMBER',
    INVOICE_STATUS: 'STATUS',
    ISSUE_DATE: 'ISSUE DATE',
    DUE_DATE: 'DUE DATE',
    SELECTED_INVOICE: 'SELECTED INVOICE',
    SIGN_UP: 'SIGN-UP',
    SIGN_UP_TRIAL:'SIGN-UP FOR TRIAL',
    EDIT_USER_DETAILS: 'EDIT USER DETAILS',
    SUSPEND_CLIENT: 'Suspend Client',
    RESTORE_CLIENT: 'Restore Client',
    SUSPEND_USER: 'Suspend User',
    RESTORE_USER: 'Restore User',
    CLIENT_COMPANY: 'Client company',
    CLIENT_IS_SUSPENDED: 'Client is suspended!',
    USER_TO_BE_SUSPENDED: 'User to be suspended',
    USER_SUSPENDED: 'User suspended',
    DELETE_CLIENT: 'DELETE CLIENT COMPANY',
    ADD_NEW_CLIENT: 'ADD NEW CLIENT',
    ADD_NEW_INVOICE: 'ADD NEW INVOICE',
    ADD_DOCUMENT: 'ADD DOCUMENT',
    EDIT_INVOICE: 'EDIT INVOICE',
    BACK: 'BACK',
    CLOSE: 'CLOSE',
    CLIENT_COMPANY_NAMES: 'CLIENT COMPANY NAME',
    AGENCY_NAME: 'AGENCY NAME',
    NUMBER_OF_CLIENTS: 'NUMBER OF CLIENTS',
    SUSPENDED_CLIENTS: 'SUSPENDED CLIENTS',
    TOTAL_CARDS: 'TOTAL CARDS',
    AGENCIES: 'AGENCIES',
    DASHBOARD: 'DASHBOARD',
    EDIT_CLIENT: 'EDIT CLIENT',
    CARD_COUNT: 'Card count',
    BILLING: 'BILLING',
    PROTECTED_DOCS: 'PROTECTED DOCUMENTS',
    Hello_user: 'Hello',
    CURRENT_COMPANY: 'Current company',
    COMPANY_INFORMATION: 'COMPANY INFORMATION',
    SEND_CALENDLY_LINK: 'SEND CALENDLY LINK',
    SEND_CALENDLY_EMAIL: 'SEND CALENDLY EMAIL',
    CALENDLY_EMAIL: 'CALENDLY INVITE RECIPIENT',
    SELF_ONBOARDING: 'SELF-ONBOARDING',
    COMPANY_IDENTITY: 'GRAPHIC IDENTITY',
    MENU_STATS: 'STATISTICS',
    MENU_INVOICES: 'INVOICES',
    MENU_CLIENT_USERS: 'CLIENT USERS',
    INITIAL_CARD_TEMPLATE: 'INITIAL BCARD TEMPLATE',
    DEFAULT_OFFICE_ADDRESS: 'DEFAULT OFFICE ADDRESS',
    SELECT_OFFICE_ADDRESS: 'PICK YOUR OFFICE ADDRESS',
    CURRENTLY_MANAGING_COMPANY: 'Currently managing',
    CARD_DESIGN_TEMPLATE: 'Business card template',
    ACCOUNT_SETTINGS: 'ACCOUNT SETTINGS',
    STREET_ADDRESS: 'STREET ADDRESS',
    LOG_OUT: 'Log out',
    SIGN_OUT: 'Sign out',
    RETURN_TO_PROFILE: 'Return to profile page',
    Loading: 'Loading',
    CONFIRM_SUBSCRIPTION: 'CONFIRM SUBSCRIPTION',
    CANCEL_SUBSCRIPTION: 'CANCEL SUBSCRIPTION',
    YOUR_SUBSCRIPTION: 'YOUR SUBSCRIPTION',
    MY_INVOICES: 'MY INVOICES',
    BILLING_HISTORY: 'BILLING HISTORY',
    CLIENT_INVOICES: 'CLIENT INVOICES',
    INVOICES: 'INVOICES',
    INVOICE_N: 'INVOICE #',
    ISSUED_ON: 'ISSUED ON',
    DUE_ON: 'DUE ON',
    PAID_ON: 'PAID ON',
    REVOLUT_LINK: 'REVOLUT LINK',
    UPLOADED_ON: 'UPLOADED ON',
    FILENAME: 'FILENAME',
    FILE: 'FILE',
    AMOUNT: 'AMOUNT',
    GROSS_AMOUNT: 'GROSS AMOUNT',
    NET_AMOUNT: 'NET AMOUNT',
    STATUS_PAID: 'PAID',
    STATUS_OPEN: 'OPEN',
    ACTIONS: 'ACTIONS',
    Paid_on: 'Paid on',
    Invoice_number: 'Invoice number',
    Invoice_amount: 'Invoice amount',
    Invoice_netAmount: 'Invoice net amount',
    Invoice_grossAmount: 'Invoice gross amount',
    Invoice_date: 'Invoice date',
    MARK_AS_PAID: 'MARK AS PAID',
    NO_DATA_YET: 'No data yet',
    No_data_details: 'A chart showing the monthly usage will appear here once enough data is collected.',
    MONTH: 'MONTH',
    MAX_ACTIVE_CARS: 'MAX ACTIVE CARDS',
    MONTHLY_USAGE: 'Monthly usage',
    MONTHLY_COST: 'MONTHLY COST STATISTICS',
    MONTHLY_USAGE_STATISTICS: 'MONTHLY USAGE STATISTICS',
    MONTHLY_USAGE_SUMMARY_TEXT: 'In this section you can see a summary the card usage for the given year. <br/>The usage is calculated based on the maximum number of cards that were active for a given month. The date on which the maximum was reached is also displayed',
    MONTHLY_COST_SUMMARY_TEXT: 'In this section you can see a summary of your costs related to this client. <br/>The costs are calculated based on the maximum number of cards that are active for this client for the given month.',
    MONTHLY_ADMIN_SUMMARY_TEXT: 'In this section you can see a summary of your costs related to all clients combined. <br/>The costs are calculated based on the maximum number of cards that were active for all of your clients, including your own company for the given month.',
    ACTIVE_CARDS: 'ACTIVE CARDS',
    MAXIMUM_ACTIVE_CARDS: 'MAX ACTIVE CARDS',
    TOTAL_ACTIVE_CARDS: 'TOTAL ACTIVE CARDS',
    PRICE_SLASH_CARD: 'Price/card',
    REACHED_ON: 'Reached on',
    MONTHLY_TOTAL: 'Monthly total',
    INVOICED_TOTAL: 'Invoiced total',
    Back: 'Back',
    Prev: 'Prev',
    Next: 'Next',
    COMPANY_BILLING_INFORMATION: 'COMPANY BILLING INFORMATION',
    CLIENT_BILLING_INFORMATION: 'CLIENT BILLING INFORMATION',
    COPY_FROM_BILLING: 'Copy from billing details',
    COMPANY_NAME: 'COMPANY NAME',
    VAT_NUMBER: 'VAT NUMBER',
    VAT_REG_NUMBER: 'VAT OR COMPANY ID NUMBER',
    BILLING_ADDRESS: 'BILLING ADDRESS',
    BILLING_ADDRESS_MORE: 'BILLING ADDRESS (STREET, CITY, ZIP CODE, COUNTRY)',
    BILLING_ADDRESS_STREET: 'BILLING ADDRESS (STREET)',
    BILLING_ADDRESS_CITY: 'CITY',
    BILLING_ADDRESS_ZIP_CODE: 'ZIP CODE',
    RESPONSIBLE: 'RESPONSIBLE',
    CONTACT_PERSON: 'CONTACT PERSON',
    EMAIL: 'E-MAIL',
    TELEPHONE: 'TELEPHONE',
    PHONE: 'PHONE',
    EDIT_INFORMATION: 'EDIT INFORMATION',
    EDIT_BILLING_INFORMATION: 'EDIT BILLING INFORMATION',
    NAME: 'NAME',
    BILLING_EMAIL: 'BILLING EMAIL',
    SAVE: 'SAVE',
    SAVE_CHANGES: 'SAVE CHANGES',
    REVERT_CHANGES: 'REVERT CHANGES',
    ADD_ADDRESS: 'ADD ADDRESS',
    EDIT_ADDRESS: 'Edit this address',
    DELETE_ADDRESS: 'Delete this address',
    ADDRESS_LABEL: 'ADDRESS LABEL',
    OFFICE_PHONE: 'OFFICE PHONE',
    OFFICE_EMAIL: 'OFFICE EMAIL',
    ADDRESS: 'ADDRESS',
    WEBSITE: 'WEBSITE',
    REMOVE_ADDRESS: 'REMOVE ADDRESS?',
    REMOVE: 'REMOVE',
    CANCEL: 'CANCEL',

    DELETE: 'DELETE',
    ADD_NEW_OFFICE_ADDRESS: 'ADD NEW OFFICE ADDRESS',
    EDIT_OFFICE_ADDRESS: 'EDIT OFFICE ADDRESS',
    ADD: 'ADD',
    ADD_PROMO: 'ADD PROMO',
    ADD_EMPLOYEE: 'ADD EMPLOYEE',
    IMPORT_FROM_CSV: 'IMPORT FROM CSV',
    TOTAL_CARD_COUNT: 'Showing results',
    RESULTS_PAGE: 'Results page',
    FIRST_NAME: 'FIRST NAME',
    LAST_NAME: 'LAST NAME',
    NAMES: 'NAMES',
    POSITION: 'POSITION',
    DEPARTMENT: 'DEPARTMENT (optional)',
    MOBILE_PHONE: 'MOBILE PHONE',
    PRIMARY_PHONE: 'PRIMARY PHONE',
    SECONDARY_PHONE: 'SECONDARY PHONE',
    SHARE_SELECTED: 'SHARE SELECTED',
    SEND_SELECTED: 'SEND SELECTED',
    SENDING_CARDS: 'Sending out employee cards',
    SEND_EMPLOYEE_CARDS_TITLE: 'SEND CARDS TO EMPLOYEES',
    SEND_CARDS: 'SEND CARDS TO EMPLOYEE(S)',
    DELETE_SELECTED: 'DELETE SELECTED',
    RESULTS_PER_PAGE: 'RESULTS PER PAGE',
    Search: 'Search',
    TYPE: 'TYPE',
    LANGUAGE: 'LANGUAGE',
    FILE_NAME: 'FILE NAME',
    DESCRIPTION: 'DESCRIPTION',
    DELETE_EMPLOYEE_CARD: 'DELETE EMPLOYEE CARD',
    EMPLOYEE_NAMES: 'EMPLOYEE NAMES',
    DELETE_USER: 'DELETE USER',
    SELECTED_USERS: 'SELECTED USERS',
    EMPLOYEE_RECIPIENTS: 'EMPLOYEES WHO WILL RECEIVE BUSINESS CARDS',
    SHARE_CARDS_CHECKBOX: 'Reset previous links for these cards',
    no_print_card_checkbox: `I don't have a printed business card`,
    delete_invoice_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO DELETE THIS INVOICE',
    delete_user_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO PERMANENTLY DELETE THIS USER',
    delete_employee_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO DELETE THOSE CARDS FOR THOSE ACCOUNTS',
    notification_success_user_delete: 'User deleted successfully!',
    delete_client_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO DELETE THIS CLIENT AND ALL CARDS RELATED TO IT',
    suspend_client_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO SUSPEND THIS CLIENT',
    restore_client_confirm_checkbox: 'YES, RESTORE THE SUBSCRIPTION OF THIS CLIENT',
    suspend_user_confirm_checkbox: 'YES, I CONFIRM I WOULD LIKE TO SUSPEND THIS USER',
    restore_user_confirm_checkbox: 'YES, RESTORE THE SUBSCRIPTION OF THIS USER',
    beware_data_deletion: 'Beware that all data will be deleted PERMANENTLY!',
    beware_suspend: 'All employee cards related to this client will be disabled immediately!',
    beware_user_suspend: 'Once suspended the user will no longer be able to login!',
    ADD_NEW_EMPLOYEE_CARDS: 'ADD NEW EMPLOYEE CARDS',
    EDIT_EMPLOYEE_CARDS: 'EDIT EMPLOYEE CARDS',
    EDIT_CLIENT_COMPANY: 'EDIT CLIENT COMPANY',
    EDIT_AGENCY_SETTINGS: 'EDIT AGENCY SETTINGS',
    ADMIN_SETTINGS: 'ADMIN SETTINGS',
    ALL_TEMPLATES: 'ALL TEMPLATES',
    USE_CUSTOM_ADDRESS: 'USE CUSTOM ADDRESS',
    OFFICE: 'OFFICE',
    CREATED_ON:'CREATED ON',
    CREATED_AT:'CREATED AT',
    UPDATED_ON:'UPDATED ON',
    SENT_ON:'SENT ON',
    Sent_on:'Sent on',
    Card_not_yet_sent:'Card not yet sent',
    Preparing_to_send:'Preparing the cards to be sent',
    VIEW_COUNT: 'VIEW COUNT',
    OFFICE_ADDRESS: 'OFFICE ADDRESS',
    NOTES: 'NOTES (KEYWORDS)',
    ZIP: 'ZIP CODE',
    COUNTRY: 'COUNTRY',
    STATE: 'STATE',
    CITY: 'CITY',
    NO_DATA_FOUND: 'No data found',
    IMPORT_FROM_CSV_INPUT_LABEL: 'Select or drop file here',
    IMPORT_FROM_CSV_CHECKBOX_LABEL: 'Overwrite data for existing employees',
    UPLOAD: 'UPLOAD',
    UPLOAD_AND_SUBMIT: 'UPLOAD AND SUBMIT',
    UPLOAD_CONTRACT: 'UPLOAD SIGNED CONTRACT<br>(.PDF File, max 10MB)',
    UPLOAD_PANEL_LOGO: 'UPLOAD PANEL LOGO<br>(.SVG OR .PNG @ 54 x 180 px max size)',
    IMPORT_FROM_CSV_DETAILS: 'For the data import process to work correctly hte information needs to be formatted correctly with specific column header labels.<br>To help you get started please directly download and fill-out the template file provided below.',
    PROFILE_INFORMATION: 'PROFILE INFORMATION',
    PROFILE: 'PROFILE',
    CONTRACTS: 'CONTRACTS',
    CONTRACTS_DETAILS: 'Your account is eligible to become a qfecard Agency! To upgrade your account and become an affiliated <strong>Agency</strong> with qfecard, you will need to follow our simple 2-step prrocess. Note that this request will automatically activate your subscription, and you will be billed accordingly.',
    SIGNED_CONTRACT: 'SIGNED CONTRACT',
    PRICING_TIER: 'PRICING TIER',
    PRICING: 'PRICING',
    SAVE_PRICES: 'SAVE PRICES',
    RESET_PRICES: 'RESET PRICES',
    Price_per_card_for_tier: 'Price per card for tier',
    AGENCY_SINCE: 'AGENCY SINCE',
    AVATAR: 'AVATAR',
    CHANGE_PASSWORD: 'CHANGE PASSWORD',
    DROP_AVATAR_HERE: 'DROP AVATAR HERE',
    ADD_PROFILE_PIC: 'ADD PROFILE PICTURE',
    NEW_PASSWORD: 'NEW PASSWORD',
    CONFIRM_NEW_PASSWORD: 'CONFIRM NEW PASSWORD',
    PASSWORD: 'PASSWORD',
    change_password_error: 'Passwords didn\'t match',
    LOGIN: 'LOGIN',
    LOGIN_EMAIL: 'LOGIN/EMAIL',
    LAST_LOGGED_ON: 'LAST LOGGED-IN ON',
    LOGIN_COUNT: 'LOGIN COUNT',
    STATUS: 'STATUS',
    PROMO: 'PROMO',
    ONBOARDED: 'Onboarded',
    INVITATION_PENDING: 'Invitation pending',
    INVITATION_LINK_COPIED: 'Invitation link copied to clipboard!',
    PROMO_LINK_COPIED: 'Promo link copied to clipboard!',
    PASSWORD_OPTIONAL: 'PASSWORD (optional)',
    ACTIVATE_USER: 'Activate user manually',
    LINK_TO_COMPANY: 'LINK TO COMPANY',
    CLIENT_OF: 'CLIENT OF',
    client_of: 'Client of',
    SEND_EMAIL: 'SEND EMAIL',
    REQUEST_TRIAL: 'REQUEST TRIAL',
    PASSWORD_CONFIRM: 'CONFIRM PASSWORD',
    RESET_PASSWORD: 'RESET PASSWORD',
    RESEND_WELCOME_EMAIL: 'RESEND CARD LINK',
    RESET_ONBOARDING_LINK: 'REGENERATE LINK',
    COPY_LINK: 'COPY LINK TO CLIPBOARD',
    TAKE_ME_TO_CARD: 'TAKE ME TO MY CARD',
    Success: 'Success',
    FORGOT_PASSWORD: 'FORGOT PASSWORD?',
    BACK_TO_LOGIN: 'BACK TO LOGIN',
    BACK_TO_DASHBOARD: 'BACK TO DASHBOARD',
    passwordForgottenError204: 'No users found with this e-mail.',
    passwordForgottenSuccess: 'You have successfully reset your password.<br>Check your email for the reset link.',
    passwordResetError204: 'The reset token is invalid.',
    passwordResetSuccess: 'Password changed successfully.<br>You can no login with the new password.',
    password_required: 'Password is required',
    card_created_success: "You have successfully created your business card!",
    request_trial_success: "Check your e-mail for your trial link!",
    request_trial_thanks: "Thank you for your interest in our product!",
    CUSTOMISE: 'CUSTOMISE',
    CUSTOMIZE_CARD: 'CUSTOMIZE CARD',
    CLIENT_LOGO: 'COMPANY LOGO',
    SHORTCUT_ICON: 'CARD SHORTCUT ICON',
    COMPANY_LOGO: 'COMPANY LOGO<br>(MAX 5MB)',
    COMPANY_LOGO_DROPZONE: 'COMPANY THUMBNAIL <br/><small>(.PNG OR .JPG @ 128 x 128 px recommended)</small>',
    AGENCY_LOGO_DROPZONE: 'AGENCY PANEL LOGO <br/><small>(.PNG OR .SVG @ 54 x 180 px max size)</small>',
    DROPZONE_FILE_TOO_BIG: 'File is too big ({{filesize}}MB). Max filesize is: {{maxFilesize}}MB',
    DROPZONE_ONLY_ONE: 'Only one file can be uploaded for this field',
    DROPZONE_INVALID_IMAGE_FILE_TYPE: 'Invalid file type. Only .png, .jpg, .jpeg, .svg are allowed',
    DROPZONE_INVALID_MIXED_FILE_TYPE: 'Invalid file type. Only PDFs and images (.png, .jpg, .jpeg) are allowed',
    COMPANY: 'COMPANY',
    APPLY_MY_SETTINGS: 'APPLY MY SETTINGS',
    APPLY_DEFAULT_SETTINGS: 'APPLY TEMPLATE DEFAULTS',
    companyLogoDropzone: 'COMPANY LOGO<br>(MAX 5MB)',
    invoiceDropzone: 'INVOICE FILE<br>(.PNG OR .JPG @ 128 x 128 px)',
    profilePictureDropzone: 'PROFILE PICTURE<br>(.PNG OR .JPG @ 256 x 256 px)',
    customizeCardDropzone: 'COMPANY LOGO IMAGE<br>(MAX 5MB)',
    clientIconDropzone: 'CARD ICON',
    clientCardScanDropzoneFront: 'SCAN OF YOUR BUSINESS CARD<br>(FRONT SIDE, MAX 5MB)',
    clientCardScanDropzoneBack: 'SCAN OF YOUR BUSINESS CARD<br>(BACK SIDE, MAX 5MB)',
    IMAGE_BG_FULL: 'UPLOAD BACKGROUND<br>(.PNG OR .SVG @ 1440 x 2960 px)',
    IMAGE_BG_TOP: 'UPLOAD TOP BACKGROUND<br>(.PNG OR .SVG @ 1440 x 2960 px)',
    IMAGE_APP_ICON: 'UPLOAD APP ICON<br>(.PNG @ 512 x 512 px)',
    SIZE_LOGO: 'LOGO SIZE',
    COLOR_NAME: 'NAME COLOR',
    COLOR_TITLE: 'TITLE COLOR',
    SIZE_TITLE: 'TITLE SIZE (%)',
    COLOR_BG_INFO: 'INFO BACKGROUND COLOR',
    COLOR_BUTTON: 'BUTTON COLOR',
    COLOR_BUTTON_TEXT: 'BUTTON TEXT COLOR',
    COLOR_READY: 'QR TITLE COLOR',
    IMG_BG_FULL: 'BACKGROUND IMAGE',
    IMG_BG_TOP: 'TOP BACKGROUND IMAGE',
    COLOR_BG_TOP: 'TOP BACKGROUND COLOR',
    COLOR_INFO: 'INFO TEXT COLOR',
    COLOR_BG_GRADIENT1: 'BACKGROUND GRADIENT 1',
    COLOR_BG_GRADIENT2: 'BACKGROUND GRADIENT 2',
    LABEL_PHONE1:'LABEL PHONE 1',
    LABEL_PHONE2:'LABEL PHONE 2',
    LABEL_EMAIL:'LABEL E-MAIL',
    LABEL_ADDRESS:'LABEL ADDRESS',
    FONT_FAMILY: 'FONT FAMILY',
    FONT_SIZE: 'FONT SIZE (px)',
    FONT_COLORS: 'FONT COLORS',
    SHAPE_COLOR: "SHAPE COLOR",
    Verified: 'Verified',
    Not_verified: 'Not verified',
    Verified_on: 'Verified on',
    Left: 'Left',
    Right: 'Right',
    Center: 'Center',
    ALIGNMENT: 'ALIGNMENT',
    Solid: 'Solid',
    Gradient: 'Gradient',
    COLORS: 'COLORS',
    PRIMARY_FONT: 'PRIMARY FONT',
    SECONDARY_FONT: 'SECONDARY FONT',
    BACKGROUND_TYPE: 'BACKGROUND TYPE',
    PRIMARY_COLOR: 'PRIMARY COLOR',
    SECONDARY_COLOR: 'SECONDARY COLOR',
    FIELD_LABELS: 'FIELD LABELS',
    show_suspended: 'Show suspended',
    linked_in: 'Linked-In',
    youtube_link: 'YouTube',
    twitter_link: 'X (Twitter)',
    instagram_link: 'Instagram',
    this_is_trial_account: 'This is a trial account',
    this_user_can_edit_design: 'This user can edit designs',
    enable_trial_for_company: 'Enable trial period for this company',
    select_trial_end_date: 'Select the trial end date',
    trial_until: 'Trial until',
    TRIAL_UNTIL: 'TRIAL UNTIL',
    notification_error_title: 'ERROR',
    notification_error_pwd_not_match: 'Passwords do not match.',
    notification_success_pwd_change: 'Password changed successfully!',
    notification_error_general: 'An error has occurred.',
    notification_share_card: 'Card invitations sent.',
    notification_card_resend: 'Your business card link was successfully e-mailed! Check your inbox!',
    notification_success_calendly: 'Calendly invite e-mail sent successfully!',
    notification_success_saved: 'Saved successfully!',
    notification_success_prices: 'Prices saved successfully!',
    notification_error_calendly: 'An error has occurred. Please try again.',
    notification_success_delete: 'Delete successful.',
    notification_success_suspend: 'Client(s) suspended!',
    notification_success_restored: 'Client(s) restored!',
    notification_success_user_suspend: 'User successfully suspended!',
    notification_success_user_restored: 'User successfully restored!',
    notification_success_import: 'Import successful.',
    notification_login_success: 'Welcome to qfecard',
    notification_login_error: 'Invalid login credentials. Please try again.',
    notification_login_expired: 'Your login sessions has expired',
    notification_onboard_link_copied: 'Onboarding link copied!',
    notification_subscribe_success: 'Subscription successful! Enjoy your new plan!',
    notification_subscribe_error: 'An error has occurred. Please contact support.',
    search_by_name: "Search by name or e-mail",
    search_by_name_company: "Search by name, e-mail or company",
    search_by_name_vat_website: "Search by name, VAT or website ",
    this_client_is_an_agency: "This company is an agency",
    this_client_can_upgrade: "Can upgrade to agency",
    agency_use_light_logo_bg: "Use light logo background",
    user_can_setup_agency: "Initial company will be an agency",
    show_suspended_clients: "Also show suspended clients",
    days_remaining: "days remaining",
    days_overdue: "days overdue",
    days_past_trial: "days past trial",
    recipient_email: "Recipient e-mail",
    send_calendly_invite: "Send Calendly invite",
    sending_invite: "Sending invite",
    sending: "Sending",
    send: "Send",
    activate_trial: "Activate trial",
    activate: 'Activate',
    trial_start_date: "Trial start date",
    tooltip_req_step1A: 'Company logo file is required.',
    tooltip_req_step1B: 'Company logo and a scanned image of your business card are required.',
    tooltip_req_step2: 'To continue please fill-out your company information.',
    tooltip_req_step3: 'To finish the process please fill-out all fields marked with red asterix.',
    tooltip_req_step_default: 'All of the fields marked with red asterix are required.',
    tooltip_DELETE_USER: 'Delete this user',
    tooltip_DELETE_INVOICE: 'Delete this invoice',
    tooltip_onboard_link: 'Employee onboarding link',
    tooltip_edit_client: 'Edit client',
    tooltip_delete_client: 'Delete client',
    tooltip_agency_settings: 'Set agency settings',
    tooltip_admin_settings: 'Set admin settings',
    tooltip_edit_pricing: 'Set pricing',
    tooltip_activate_trial: 'Activate trial',
    tooltip_send_calendly: 'Send Calendly invite',
    tooltip_agency_panel_logo: 'This logo will be used in the agency panel, replacing the default qfecard logo.',
    tooltip_client_thumbnail: 'This image is required for the client to be visible in the client list.',
    tooltip_estimated_usage: 'This is an estimated value based on the current usage',
    tooltip_invoice_file: '<div class="custom-stuff">' +
        '<h4>The invoice file that your client will download.</h4>' +
        '<p>Use a PDF file or.</p>' +
        '</div>',
    tooltip_profile_pic: '<div class="custom-stuff">' +
        '<h4>Profile picture for the employee.</h4>' +
        '<p>Use an image file (JPG or PNG) that is up to 512px width and height.</p>' +
        '</div>',
    tooltip_card_scan:   '<div class="custom-stuff">' +
        '<h4>Please take a picture or a scan of your existing business card.</h4>' +
        '<p>Our design team will use this image to elaborate a design for your new qfecard.</p>' +
        '</div>',
    tooltip_app_icon:   '<div class="custom-stuff">' +
                            '<h4>This will be the icon that shows <br>on the device of each employee.</h4>' +
                            '<p>Use a 512 x 512px PNG image</p>' +
                        '</div>',
    tooltip_comp_logo: '<div class="custom-stuff">' +
                            '<h4>The logo that will appear on the <br> business card design.</h4>' +
                            '<p>Use a PNG or an SVG image</p>' +
                       '</div>',
    tooltip_background: '<div class="custom-stuff">' +
                            '<h4>Background image for the card.</h4>' +
                            '<p>Use a PNG or an SVG image with a resolution of 1440 x 2960 px</p>' +
                        '</div>',
    privacy_checkbox: 'I have read',
    the_privacy_policy: 'The privacy policy',
    terms_checkbox: 'I agree to the',
    terms_and_conditions: 'Terms and Conditions',
    please_enter_positive_number: 'Please enter a positive number',
    invalid_phone_number: 'Invalid phone number',
    invalid_credit_card: 'This is not a valid credit card number',
    password_requirements: 'Your password must be at least 8 characters long and contain at least one uppercase, one lowercase, one special character and one digit',
    urL_field_invalid: 'This is not a valid URL',
    field_invalid: 'The {_field_} is invalid',
    field_required: 'The {_field_} is required',
    field_required_simple: 'This field is required and can not be empty',
};
