import Vue from 'vue'
import underscore from 'vue-underscore'
import App from './App.vue'
import Notifications from 'vue-notification'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from "moment";

import GlobalLoader from './components/global/elements/GlobalLoader.vue'
import SpinnerStandard from "@/components/global/elements/SpinnerStandard.vue";
import TrialNotice from './components/global/elements/TrialNotice.vue'

import InputField from './components/global/fields/InputField'
import DateField from './components/global/fields/DateField'
import ButtonField from './components/global/fields/ButtonField'
import ColorField from './components/global/fields/ColorField'
import SliderField from './components/global/fields/SliderField'
import CheckboxField from './components/global/fields/CheckboxField'
import SelectField from './components/global/fields/SelectField'
import DropzoneField from './components/global/fields/DropzoneField'
import DropzoneImageField from './components/global/fields/DropzoneImageFied'

import VTooltip from 'v-tooltip'

import {clickOutside} from "./directives/clickOutside";

import './translations/ml';

import VueGoodWizard from 'vue-good-wizard';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles/bootstrap-corrections.scss'
import './assets/styles/utilities.scss'

const Parse = require('parse');
Parse.initialize(`${process.env.VUE_APP_PARSE_APP_ID}`, `${process.env.VUE_APP_PARSE_SECRET}`);
Parse.serverURL = `${process.env.VUE_APP_PARSE_URL}`;


// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.config.productionTip = false;
library.add(fas);
library.add(fab);
library.add(far);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('global-loader', GlobalLoader);
Vue.component('input-field', InputField);
Vue.component('date-field', DateField);
Vue.component('button-field', ButtonField);
Vue.component('color-field', ColorField);
Vue.component('slider-field', SliderField);
Vue.component('checkbox-field', CheckboxField);
Vue.component('select-field', SelectField);
Vue.component('dropzone-field', DropzoneField);
Vue.component('dropzone-image-field', DropzoneImageField);
Vue.component('spinner-standard', SpinnerStandard);

Vue.component('trial-notice', TrialNotice);

Vue.directive('v-click-outside', clickOutside);


import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

let quilOptions = {
    modules: {
        toolbar: ['code']
    }
}

Vue.use(VueQuillEditor, quilOptions);


Vue.filter('dateFormat', function(value) {
    if (!value) {
        return '';
    }
    // Using moment.js to format the date
    return moment(value).format('DD/MM/YYYY');
});

Vue.filter('trimFilename', function (value) {
    if (!value) return '';
    value = value.toString();

    // Extract the filename by splitting on the underscore
    const parts = value.split('_');

    // If the filename is properly formatted, it should have at least 2 parts
    if (parts.length < 2) return value;

    // Remove the first part (the random string) and return the rest
    return parts.slice(1).join('_');
});

Vue.filter('currency', (value) => {
    const currency = value.currency || 'EUR';
    let amount = value.amount || 0;
    if(typeof amount === 'string') amount = parseFloat(amount);
    const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
    return formatted;
});

Vue.filter('thousands', (value) => {
    const formatted = new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(value);
    return formatted;
});

Vue.filter('langLabel', (value) => {
    let languages = [
        {code: 'en', label: 'English'},
        {code: 'de', label: 'German'},
        {code: 'bg', label: 'Bulgarian'},
        {code: 'fr', label: 'French'},
        {code: 'es', label: 'Spanish'},

    ];
    let lang = languages.find(lang => lang.code == value);
    return lang.label;
});

Vue.filter('docTypeLabel', (value) => {
    let languages = [
        {code: 'subscribe_terms', label: 'Subscription terms'},
        {code: 'privacy_policy', label: 'Privacy Policy'},
        {code: 'cancellation_terms', label: 'Terms of Cancellation'},
    ];
    let lang = languages.find(lang => lang.code == value);
    return lang.label;
});

Vue.use(VueAxios, axios);
Vue.use(Notifications);
Vue.use(underscore);
Vue.use(VTooltip);
Vue.use(VueGoodWizard);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.prototype.$notificationAnimation = {
    enter: {
        opacity: [1, 0],
        translateX: [0, -300],
        scale: [1, 0.2]
    },
    leave: {
        opacity: [0, 1],
        height: 0
    }
};

Vue.prototype.$goToLogin = function() {
    let self = this;
    this.$store.dispatch('logout').then().catch(function(){
        self.$store.commit('setIsAuthenticated', false);
        self.$store.commit('setUser', null);
        self.$router.push('/auth')
        self.$notify({
            group: 'notification',
            type: 'error',
            text: self.$ml.get('notification_login_expired')
        });
    });
};

Vue.prototype.$checkTokenExp = function(err) {
    //let self = this;
    if(err.code == 209){
        this.$goToLogin();
    }else {
        //console.log(err);
    }
};


Vue.prototype.$getPricingTier = function(cardCount) {
    let tiers = [
        {level: 1, cards: 100, price: 2.00},
        {level: 2, cards: 500, price: 1.90},
        {level: 3, cards: 2000, price: 1.80},
        {level: 4, cards: 10000, price: 1.70},
        {level: 5, cards: 10000, price: 1.50},
    ];

    let pricingTier = tiers.find(tier => cardCount <= tier.cards);

    return pricingTier;

}

Vue.prototype.$toggleModal = function(componentName) {
    this.$store.commit('toggleDashboardModal', {name: componentName});
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
