<template>
    <transition name="fade">
        <div class="trial-stripe" v-if="trialOpen" :class="addClass">
            <div v-if="!designReady" class="notice-middle py-1">
                <div class="d-flex flex-column">
                    <span class="notice-text ">Your digital business card design is currently being prepared! Card preview and sharing is therefore not yet available! </span>
                    <span class="notice-text ">You will receive a notification e-mail once everything is ready.</span>
                </div>

            </div>

            <div v-else class="notice-middle">
                <span class="notice-text mr-2" v-if="hasEverSubscribed">Your subscription is active only until {{nextBillingDate}}! </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining > 0">Your account is in trial period. You currently have <strong>{{trialDaysRemaining}} day(s)</strong> remaining. </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining == 0">Your trial is expiring <strong>today!</strong> Click the button on the right to avoid account suspension! </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining < 0">Your account trial has expired <strong>{{-1*trialDaysRemaining}} day(s) ago!</strong></span>
                <button-field v-if="hasEverSubscribed"
                              @click="openSubscribe"
                              color="dark" variant="primary"
                              :icon="`check`">Reactivate Subscription</button-field>
                <button-field v-else
                              @click="openSubscribe"
                              color="dark" variant="primary"
                              :icon="`check`">Activate Subscription</button-field>
            </div>
            <div class="notice-close_control"><font-awesome-icon icon="times" @click="closeTrialNotice"></font-awesome-icon></div>
        </div>
    </transition>
</template>

<script>

import Parse from "parse";
import moment from "moment/moment";

export default {
    data() {
        return {
        trialNoticeOpen: true
        }
    },
    computed: {
        addClass(){
            if(!this.designReady){
                return 'design-in-progress';
            }
            if(this.trialDaysRemaining < 0){
                return 'trial-expired';
            }else if(this.trialDaysRemaining == 0){
                return 'trial-expiring';
            }else{
                return 'trial-active';
            }
        },
        designReady() {
            if(this.userCompany && this.userCompany.attributes.status !== '01_new'){
                return true;
            }else{
                return false;
            }
        },
        hasEverSubscribed(){
            let hasSubscribed = this.userCompany ? this.userCompany.attributes.subscribedOn : false;
            return hasSubscribed;
        },
        nextBillingDate() {
            const today = moment();
            const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

            if (today.isBefore(thirdOfThisMonth)) {
                // If today's date is before the 3rd of the current month
                return thirdOfThisMonth.format('D/M/YYYY');
            } else {
                // If today's date is on or after the 3rd of the current month
                const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                return thirdOfNextMonth.format('D/M/YYYY');
            }
        },
        curUser(){
            return Parse.User.current();
        },
        userCompany(){
            return this.$store.state.userCompany;
        },
        curRoute(){
          return this.$route.name;
        },

        trialOpen() {
            let userTrial = this.curUser ? this.curUser.attributes.trialUntil : false;
            let companyTrial = this.userCompany ? this.userCompany.attributes.trialUntil : false;

            if(this.trialNoticeOpen &&
                (userTrial || companyTrial)
                && this.curRoute !== 'suspended'
                && this.curRoute !== 'profile'
                && this.curRoute !== 'terms-regular'
                && this.curRoute !== 'terms-agency'
                && this.curRoute !== 'activate-subscription'
            ) {
                return true;
            } else  {
                return false;
            }
        },

        trialDaysRemaining() {
            let userTrial = this.curUser && this.curUser.attributes.trialUntil ? moment(this.curUser.attributes.trialUntil) : null;
            let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

            let maxTrial = null;

            // Check if both dates are valid
            if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                maxTrial = moment.max(userTrial, companyTrial);
            } else if (userTrial && userTrial.isValid()) {
                // Only user trial date is valid
                maxTrial = userTrial;
            } else if (companyTrial && companyTrial.isValid()) {
                // Only company trial date is valid
                maxTrial = companyTrial;
            }

            if (maxTrial) {
                let remainingTrial = maxTrial.diff(moment(), 'days');
                return remainingTrial;
            } else {
                return 0;
            }
        },

        hasTrial() {
            let hasTrial = this.curUser ? this.curUser.attributes.trialUntil : false;

            if(hasTrial) {
                return true;
            } else  {
                return false;
            }
        },
    },
    methods: {
        closeTrialNotice(){
            this.trialNoticeOpen = false;
        },



        openSubscribe(){
               this.$router.push({name: 'activate-subscription'});
        },
    },

}
</script>

<style>

.design-in-progress {
    background-color: #f8d7da;
    color: #721c24;
}

</style>
